<template>
  <div class="flex">
    <div v-if="showCap" class="flex" data-recording-sensitive>
      <meeting-guest
        v-for="attendee in guestsSlice"
        :guest="attendee"
        :online="attendee.online"
        :key="attendee.email"
        :eventStatus="eventStatus"
        v-bind:showOnlineStatus="showOnlineStatus"
        v-bind:showDetails="showDetails"
      ></meeting-guest>
      <meeting-guest-cap :guests="guestsNoResources"></meeting-guest-cap>
    </div>
    <div v-else class="flex" data-recording-sensitive>
      <!-- show all guests -->
      <meeting-guest
        v-for="attendee in guestsNoResources"
        :guest="attendee"
        :online="attendee.online"
        :key="attendee.email"
        :eventStatus="eventStatus"
        v-bind:showOnlineStatus="showOnlineStatus"
        v-bind:showDetails="showDetails"
      ></meeting-guest>
    </div>
  </div>
</template>

<script>
// change this one for size based, not browser base
import MeetingGuest from '@/components/MeetingGuest/MeetingGuest';
import MeetingGuestCap from '@/components/MeetingGuest/MeetingGuestCap';

export default {
  name: 'MeetingGuests',
  components: {
    MeetingGuest,
    MeetingGuestCap,
  },
  props: {
    guests: {
      type: Array,
      required: true,
      default: () => [],
    },
    showDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOnlineStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    eventStatus: {
      type: String,
      required: false,
    },
  },
  data() {
    return { showMaxGuests: 0 };
  },
  mounted() {
    // show cap if more than 6 guests
    this.showMaxGuests = 6;
  },
  computed: {
    guestsNoResources() {
      return this.guests.filter((a) => !a.resource);
    },
    showCap() {
      return this.guestsNoResources.length > this.showMaxGuests;
    },
    guestsSlice() {
      // get just first 2 or 6
      return this.guestsNoResources.slice(0, this.showMaxGuests);
    },
  },
};
</script>
