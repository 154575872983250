<template>
  <div
    class="relative"
    @mouseout="delayedHoverOff"
    @mouseover="openHover"
    data-recording-sensitive
  >
    <!-- Guest circle -->
    <div
      class="relative"
      :class="eventStatus == 'pastEvent' ? 'opacity-50' : ''"
      style="margin-left: 0.1rem; z-index: 9"
    >
      <!-- Online indicator -->
      <!-- <span
        class="h-2 w-2 absolute z-10"
        style="margin-left:1.5rem;margin-top:-0.50rem;"
        v-show="guest.online && showOnlineStatus"
      >
        <span
          class="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-alertvalidation opacity-75"
          style="margin-top:0.47rem;"
        ></span>
        <span
          class="relative inline-flex rounded-full h-2 w-2 bg-alertvalidation"
        ></span>
      </span> -->

      <!-- Initials / face -->
      <div
        class="guestcircle flex overflow-hidden rounded-full items-center justify-center bg-grey4 relative"
        :class="sizeComponent"
      >
        <svg
          class="h-full w-full absolute"
          preserveAspectRatio="none"
          viewBox="0 0 100 100"
        >
          <!-- Move, Curve (control, control, dest), Line, Fill -->
          <path
            d="M-31.31,57.72 C51.06,72.53 47.11,16.28 105.25,29.11 L116.53,-112.00 L-34.14,-55.75 Z"
            opacity="0.3"
            fill-opacity="null"
            stroke-opacity="null"
            fill="#fff"
          />
        </svg>
        <div class="guestinitial text-white uppercase font-medium">
          {{ guests.length }}
        </div>
      </div>
    </div>

    <!-- Hover card -->
    <transition name="guesthover">
      <!-- -(0.5*9.6) + (0.5*2.0) (h-8)-->
      <div
        class="absolute"
        style="left: -10.95rem; z-index: 11"
        v-if="showGuestHover"
      >
        <!-- Arrow -->
        <div
          class="arrowguestcap absolute h-0 w-0 border-solid border-l-4 border-r-4 border-t-8 border-b-8 -mt-2 text-base4"
          style="
            border-left-color: rgba(255, 255, 255, 0);
            border-right-color: rgba(255, 255, 255, 0);
            border-top-color: rgba(255, 255, 255, 0);
            background-clip: padding-box;
            border-bottom-color: currentColor;
            right: 1.8rem;
          "
        ></div>

        <!-- Card -->
        <div
          class="bg-base4 rounded-xl shadow-sm pt-2 px-2 mt-2"
          style="width: 14rem"
        >
          <!-- Text details -->
          <div
            v-for="guest in guests"
            :key="guest.email"
            class="text-grey1 text-sm flex pb-2 cursor-default"
          >
            <div class="">
              <meeting-guest
                :guest="guest"
                v-bind:showOnlineStatus="true"
                v-bind:showDetails="true"
                v-bind:showName="true"
                v-bind:fromCapList="true"
              ></meeting-guest>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import MeetingGuest from '@/components/MeetingGuest/MeetingGuest';

export default {
  name: 'MeetingGuestCap',
  components: { MeetingGuest },
  props: {
    guests: {
      type: Array,
      required: true,
    },
    sizeComponent: {
      type: String,
      required: false,
      default: 'size1',
    },
    eventStatus: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showGuestHover: false,
      closeTimer: null,
    };
  },
  mounted() {
    //console.log('guest:', this.guest);
  },
  computed: {
    getInitials: function () {
      if (this.guest.initials) return this.guest.initials;
      else if (this.guest.email) return this.guest.email.slice(0, 2);

      return '';
    },
  },
  methods: {
    delayedHoverOff: function () {
      this.closeTimer = setTimeout(() => {
        this.showGuestHover = false;
      }, 200);
    },
    openHover: function () {
      clearTimeout(this.closeTimer);
      this.showGuestHover = true;
    },
  },
};
</script>
